<script lang="ts" setup>
import { useUserProfileStore } from "~/store/user/user_prifile.store";

interface TabItem {
    path: string;
    icon: string;
    name: string;
    child_pages: string[];
}

const tab_list: TabItem[]  = [
    {
        name: 'My tasks',
        icon: 'ri:dashboard-line',
        path: '/master-office',
        child_pages: []
    },
    {
        name: 'Chats',
        icon: 'ri:chat-3-line',
        path: '/master-office/chats',
        child_pages: []
    },
    {
        name: 'Task Search',
        icon: 'ri:search-2-line',
        path: '/master-office/task-search',
        child_pages: []
    },
    {
        name: 'Task archive',
        icon: 'ri:archive-line',
        path: '/master-office/task-archive',
        child_pages: []
    },
    {
        name: 'My profile',
        icon: 'ri:user-3-line',
        path: '/master-office/profile',
        child_pages: ['/master-office/profile/notification-settings',
            '/master-office/profile/privacy-security'/* , '/master-office/profile/billing' */]
    },
    {
        name: 'Portfolio',
        icon: 'ri:grid-line',
        path: '/master-office/portfolio',
        child_pages: []
    }
]

const userProfile = useUserProfileStore();
const route = useRoute()
const newMessagesCount = computed(() => userProfile.getNewMessagesCount())

const isCurrentRoute = (item: TabItem) => {
    return route.path === item.path || item.child_pages.includes(route.path)
}

</script>

<template>
    <div class="min-h-screen flex flex-col">
        <!-- <LoaderLottieAnimation /> --> <!-- Анимация загрузки (Lottie) -->
        <LayoutsHeader /> <!-- Компонент шапки сайта -->

        <div class="container mx-auto px-4 flex flex-col flex-grow min-h-[600px]">
            <div class="mx-3 my-8 flex justify-start flex-grow">
                <div class="ys-profile-menu">
                    <div class="ys-profile-logo">
                        <PagesOfficeUserProfileCard :username="userProfile.FullName" :role="userProfile.Role"
                            :email="userProfile.Email" :photo="userProfile.ImageUrl"
                            @click="navigateTo(`/${(userProfile.Role).toLowerCase()}-office/profile`);" />
                    </div>
                    <div class="ys-profile-menu-list">
                        <div v-for="item in userProfile.AvailableRoles">
                            <div class="ys-master-mode-box mb-5 flex items-center justify-between"
                                v-if="item === 'MASTER'">
                                {{ $t("Master Mode") }}
                                <PagesOfficeMasterModeSwitcher />
                            </div>
                        </div>
                        <div class="list-title">{{ $t("MAIN") }}</div>
                        <NuxtLink class="ys-profile-menu-item flex items-center my-1 justify-between"
                            :class="{ current: isCurrentRoute(item) }" :to="item.path" v-for="item in tab_list">
                            <span>
                                <Icon size="18" class="menu-item-icon ltr:mr-1 rtl:ml-1" :name="item.icon" />
                                {{ $t(item.name) }}
                            </span>
                            <span class="flex items-center">
                                <span class="new-messages-icon" v-if="item.name === 'Chats' && newMessagesCount">{{ newMessagesCount }}</span>
                                <Icon v-if="isCurrentRoute(item)" size="24" class="text-gray-500 rtl:rotate-180 ltr:ml-2 rtl:mr-2" name="ri:arrow-drop-right-line" />
                            </span>
                        </NuxtLink>

                        <!-- <NuxtLink v-if="userProfile.Role === 'MASTER'" to="/master-office/promotion">
                            <PagesOfficeMasterMenuPromoPlaque />
                        </NuxtLink> -->
                    </div>


                </div>
                <div class="ltr:border-l rtl:border-r border-gray-200 grow">
                    <NuxtPage />
                </div>
            </div>
        </div>

        <LayoutsFooter class="mt-auto" />
    </div>
</template>

<style scoped lang="scss">
.ys-profile-logo {
  height: 88px;
  padding: 12px;
  gap: 10px;
}


.ys-profile-menu {
  width: 272px;
  gap: 0;

  .ys-profile-menu-list {
    height: 222px;
    width: 272px;
    padding: 20px 20px 16px 20px;
    gap: 20px;

    .list-title {
      height: 24px;
      padding: 4px;
      gap: 8px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: rgba(134, 140, 152, 1);
    }

    .ys-profile-menu-item {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(82, 88, 102, 1);

      height: 36px;
      padding: 8px 12px 8px 12px;
      gap: 8px;
      border-radius: 8px;

      &:hover {
        color: rgba(10, 13, 20, 1);
        background: rgba(246, 248, 250, 1);
      }

      &:active {
        color: rgba(10, 13, 20, 1);
        background: rgba(246, 248, 250, 1);
      }
    }

    .current {
      color: rgba(10, 13, 20, 1);
      background: rgba(246, 248, 250, 1);

      .menu-item-icon {
        color: theme('colors.primaryblue.500');
      }
    }
  }
}

.ys-master-mode-box {
  width: 232px;
  height: 36px;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(10, 13, 20, 1);
}
</style>